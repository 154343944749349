        
import { __ } from "@adac/core-model";
import React, { useState } from "react";
import styled from "styled-components";
import { Filters } from "./ListView";

const FilterInput = styled.input`
  padding: 5px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

interface FilterChangeProps {
  handleFilterChange: (key: keyof Filters, value: string) => void;
}  


export function DateFilter({handleFilterChange}: FilterChangeProps){
  const [toDateFilter, setToDateFilter] = useState('');
  const [fromDateFilter, setFromDateFilter] = useState('');

  return (
    <>
      <FilterInput
        value={fromDateFilter}
        onChange={(e) => {
          const fromDateFilterValue = e.target.value;
          setFromDateFilter(fromDateFilterValue);
          handleFilterChange("createdAtFrom", fromDateFilterValue); 
        }}
        type="text"
        placeholder="from (2024-01-01)"
      />
      <FilterInput
        value={toDateFilter}
        onChange={(e) => {
          const toDateFilterValue = e.target.value;
          setToDateFilter(toDateFilterValue);
          handleFilterChange("createdAtTo", toDateFilterValue); 
        }}
        type="text"
        placeholder="to (2025-01-01)"
      />
    </>
)};

export function StreetAndHouseNumberFilter({ handleFilterChange }: FilterChangeProps){
  const [streetFilter, setStreetFilter] = useState('');
  const [houseNumberFilter, setHouseNumberFilter] = useState('');

  return (
    <>
      <FilterInput
        value={streetFilter}
        onChange={(e) => {
          const streetFilterValue = e.target.value;
          setStreetFilter(streetFilterValue);
          handleFilterChange("customerAddressStreet", streetFilterValue); 
        }}
        type="text"
        placeholder="Rottmannstraße"
      />
      <FilterInput
        value={houseNumberFilter}
        onChange={(e) => {
          const houseNumberFilterValue = e.target.value;
          setHouseNumberFilter(houseNumberFilterValue);
          handleFilterChange("customerAddressNumber", houseNumberFilterValue); 
        }}
        type="text"
        placeholder="12"
      />
    </>
  )
}

export function ZipAndCityFilter({ handleFilterChange }: FilterChangeProps){
  const [cityFilter, setCityFilter] = useState('');
  const [zipFilter, setZipFilter] = useState('');

  return (
    <>
      <FilterInput
        value={zipFilter}
        onChange={(e) => {
          const zipFilterValue = e.target.value;
          setZipFilter(zipFilterValue);
          handleFilterChange("customerAddressZip", zipFilterValue); 
        }}
        type="text"
        placeholder="80333"
      />
      <FilterInput
        value={cityFilter}
        onChange={(e) => {
          const cityFilterValue = e.target.value;
          setCityFilter(cityFilterValue);
          handleFilterChange("customerAddressCity", cityFilterValue); 
        }}
        type="text"
        placeholder="München"
      />
    </>
  )
}

export function CommissioningReferenceFilter({ handleFilterChange }: FilterChangeProps){
  const [commissioningReferenceFilter, setCommissioningReferenceFilter] = useState('');

  return (
    <FilterInput
      value={commissioningReferenceFilter}
      onChange={(e) => {
        const commissioningReferenceFilterValue = e.target.value;
        setCommissioningReferenceFilter(commissioningReferenceFilterValue);
        handleFilterChange("commissioningReference", commissioningReferenceFilterValue); 
      }}
      type="text"
      placeholder="20240416-105940"
    />
  )
}

const Dropdown = styled.select`
  padding: 5px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px; // Optional: adjust font size to match your design
`;

export function InvoiceFilter({ handleFilterChange }: FilterChangeProps) {
  const [selectedValue, setSelectedValue] = useState('');

  const options = [
    { value: '', label: 'Select an option' },
    { value: 'true', label: __('yes') },
    { value: 'false', label: __('no') },
  ];

  function onFilterChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.target.value;
    setSelectedValue(value);

    if(value === options[0].label){
      handleFilterChange('hasInvoice', '');
    } else {
      handleFilterChange('hasInvoice', value);
    }
  }

  return (
    <Dropdown
      value={selectedValue}
      onChange={onFilterChange}
    >
      {options.map((option) => (
        <option key={option.label} value={option.value}>
          {option.label}
        </option>
      ))}
    </Dropdown>
  );
}