import { AuthStore } from '@adac/core-view';
import {
  axios, CompanyBoss, HTTP_FORBIDDEN, HTTP_UNAUTHORIZED, HTTP_PRECONDITION_FAILED,
} from '@adac/core-model';

export const setupNetworking = (authStore: AuthStore<CompanyBoss>) => {
  axios.interceptors.response.use(value => value, async (error) => {
    if (error.config && ([HTTP_FORBIDDEN, HTTP_UNAUTHORIZED, HTTP_PRECONDITION_FAILED].includes(error?.response?.status))) {
      // TODO:
      // window.location.href='/';
      authStore.deleteToken();
    }
    return Promise.reject(error);
  });
  // TODO: add bearer token from localstorage here, so that we don't have to pass it everywhere
};
