import React, {
} from 'react';
import { RouterContextContainer } from '@adac/core-view';
import { Route } from 'react-router-dom';
import { LayoutContainer } from './components/layout';
import StoresContext from './stores';
import { setupNetworking } from './networking/setupNetworking';

// eslint-disable-next-line arrow-body-style
const App = () => {
  const { auth: authStore } = React.useContext(StoresContext);
  setupNetworking(authStore);

  return (
    <RouterContextContainer
      // setCaseToken={setCaseToken}
      routes={[<Route path="/login"><h1>ERROR 500</h1> TODO: implement Login form </Route>]}
    >
      <LayoutContainer />
    </RouterContextContainer>
  );
};

export default App;
