import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  Page, Title, SubPage, ErrorMessage, LocalizableError,
  useFormikAuth,
  useRouter,
  Text,
  ConfirmTokenForm,
  LoginPage as LoginPageComponent,
  AuthFormValues,
  requestMFATokenKey,
  confirmToken,
} from '@adac/core-view';
import {
  __,
} from '@adac/core-model';

import { FormikHelpers } from 'formik';
import StoresContext, { useOverlay } from '../../stores';
import { PathNames } from '../layout/LoginRouterContainer';
import { buttonStyles } from './buttonStyles';

export const LayoutStyles = styled(Page)`
  height: 100%;
  display: flex;
  flex-direction: column;

  fieldset {
    ${buttonStyles}
  }
`;

export const LoginPage = () => {
  const setOverlay = useOverlay();
  const { auth: { url, setToken } } = React.useContext(StoresContext);
  const { history: { push } } = useRouter();
  const [username, setUsername] = useState<string | undefined>(undefined);

  const onError=(error: LocalizableError) => {
    if (error.message.match(/(Too many attempts)|(expired)/)) setTokenKey('');
    setOverlay(<ErrorMessage>{__(error.toLocalizedString ? error.toLocalizedString() : error.toString())}</ErrorMessage>);
  };

  const { onSubmit: onStartLogin, result: tokenKey, setResult: setTokenKey } = useFormikAuth(requestMFATokenKey(url), onError);

  const { onSubmit: onConfirmSubmit, result: jwtToken } = useFormikAuth(confirmToken, onError);

  useEffect(() => {
    setToken(jwtToken || '');
  }, [jwtToken, setToken]);

  const onConfirm = async (values: AuthFormValues, actions: FormikHelpers<AuthFormValues>) => {
    await onConfirmSubmit(values, actions);
    if (jwtToken) push('/');
  };

  const onStartSubmit = async (values: AuthFormValues, actions: FormikHelpers<AuthFormValues>) => {
    setUsername(values.id);
    await onStartLogin(values, actions);
  };

  if (tokenKey) {
    return (
      <LayoutStyles>
        <ConfirmTokenForm tokenKey={tokenKey} onSubmit={onConfirm} onCancel={() => setTokenKey('')}>
          <SubPage>
            <Title>{__('Please login to SND Admin')}</Title>
            <br />
            <br />
            <Text>
              {__('verificationMessageStart')}
              {username}
              {__('verificationMessageEnd')}
            </Text>
          </SubPage>
        </ConfirmTokenForm>
      </LayoutStyles>
    );
  }

  return (
    <LayoutStyles>
      <LoginPageComponent onSubmit={onStartSubmit} onForgot={() => push(PathNames.FORGOT_PASSWORD)}>
        <Title>{__('Please login to SND Admin')}</Title>
      </LoginPageComponent>
    </LayoutStyles>
  );
};
