import { __ } from '@adac/core-model';
import {
  Icon, Text, themeConfig, useRouter, View,
} from '@adac/core-view';
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { CaseTableCellItem } from './composite/caseListComponents';
import { CommissioningReferenceFilter, DateFilter, InvoiceFilter, StreetAndHouseNumberFilter, ZipAndCityFilter } from './CaseTableFilters';

const TableOverflow = styled.div`
  overflow-x: auto;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 12px;
`;

const TableHead = styled.thead`
  background-color: ${themeConfig.colors.backgroundLight};
`;

const TableBody = styled.tbody`
  background-color: ${themeConfig.colors.background};
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
  cursor: pointer;

  &:hover {
    background-color: ${themeConfig.colors.backgroundLight};
  };
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  font-weight: bold;
  font-size: 14px;

  & > input {
    display: flex;
  }
`;

const TableCell = styled.td`
  padding: 16px;
  text-align: left;
  font-size: 14px;
`;

const InvoiceStyles = styled(View)`
  display: flex;
  flex-direction: row;
`;

const InvoiceText = styled(Text)`
  margin-left: 6px;
  font-size: 14px;
`

interface CaseTableProps {
  items: CaseTableCellItem[];
  setFilterParams: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  showInvoice: boolean;
}

export const CaseTable = ({ items, showInvoice, setFilterParams }: CaseTableProps) => {
  const { history: { push } } = useRouter();
  
  const handleFilterChange = (key: string, value: string) => {
    setFilterParams((prev) => {
      const newFilters = { ...prev };
  
      if (value) {
        newFilters[key] = value;
      } else {
        const { [key]: _, ...rest } = newFilters; 
        return rest;
      }
  
      return newFilters;
    });
  };

  const renderInvoiceInfo = ({ cancelled, invoiceDocument }: CaseTableCellItem) => {
    const hasInvoiceText = invoiceDocument ? __('yes') : __('no');

    if(invoiceDocument){
      return (
        <InvoiceStyles>
          <Icon name="invoice" color={themeConfig.colors.ready} />
          <InvoiceText>{`${hasInvoiceText[0].toUpperCase()}${hasInvoiceText.slice(1)}`}</InvoiceText>
        </InvoiceStyles>
      );
    }

    if (cancelled !== 'no') {
      return (
        <InvoiceStyles>
          <Icon name="invoice" color={themeConfig.colors.gray} />
          <InvoiceText>{__(`cancelled:${cancelled}`)}</InvoiceText>
        </InvoiceStyles>
      );
    };

    return (
      <InvoiceStyles>
        <Icon name="invoice" color={themeConfig.colors.warning} />
        <InvoiceText>{`${hasInvoiceText[0].toUpperCase()}${hasInvoiceText.slice(1)}`}</InvoiceText>
      </InvoiceStyles>
    );
  };

  return (
    <TableOverflow>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>{__('Case Id')}</TableHeader>
            <TableHeader>{__('Time of Order')}</TableHeader>
            <TableHeader>{__('Street')}</TableHeader>
            <TableHeader>{__('Customer City')}</TableHeader>
            {showInvoice && <TableHeader>{__('Invoice')}</TableHeader>}
          </TableRow>
          <TableRow>
            <TableHeader>
              <CommissioningReferenceFilter handleFilterChange={handleFilterChange}/>
            </TableHeader>
            <TableHeader>
              <DateFilter handleFilterChange={handleFilterChange}/>
            </TableHeader>
            <TableHeader>
              <StreetAndHouseNumberFilter handleFilterChange={handleFilterChange}/>
            </TableHeader>
            <TableHeader>
                <ZipAndCityFilter handleFilterChange={handleFilterChange}/>
            </TableHeader>
            {showInvoice && (
              <TableHeader>
                <InvoiceFilter handleFilterChange={handleFilterChange}/>
              </TableHeader>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((cell) => (
            <TableRow onClick={() => push(cell.link)}>
              <TableCell>{cell.commissioningReference}</TableCell>
              <TableCell>{moment(new Date(cell.timeOfOrder)).format('DD/MM/YYYY')}</TableCell>
              <TableCell>{cell.street}</TableCell>
              <TableCell>{cell.place}</TableCell>
              {showInvoice && (
                <TableCell>
                  {renderInvoiceInfo(cell)}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableOverflow>
  );
};
