import { useContext, useEffect } from 'react';
import { getMyCompany } from '../networking/driverEndpoints';
import StoresContext from '../stores';

export const useMyCompany = () => {
  const { auth, company } = useContext(StoresContext);

  useEffect(() => {
    const getCompanies = async (token: string) => {
      try {
        const res = await getMyCompany(token);
        company.setMe(res);
      } catch (error) {
        company.setMe(undefined);
      }
    };
    if (auth.token) getCompanies(auth.token);
  }, [auth.token, company]);

  return company.getMe;
};
