import React, {
  useEffect, useState, useContext,
  useCallback,
} from 'react';
import styled from 'styled-components';

import {
  TextField, View, Text, Button, ButtonTitle, Icon, themeConfig,
  Children,
} from '@adac/core-view';
import { __ } from '@adac/core-model';

import Pagination from './Pagination';

import StoreContext from '../stores';

const Styles = styled.div``;

const SearchAndFilterArea = styled(View)`
  display: flex;
  &>* {
    flex-grow: 1;
  }
`;

const FilterStyles = styled(View)`
  display: flex;
  align-items: center;
  flex-grow: 0;

  &>*:first-child {
    margin-left: 16px;
    margin-right: 4px;
  }
`;

const FilterText = styled(Text)`
  font-weight: bold;
`;

const Filter = () => (
  <FilterStyles>
    <Icon name="filter" />
    <FilterText>{__('Filter')}</FilterText>
  </FilterStyles>
);

const ListPagination = styled(Pagination)`
  margin-top: 44px;
`;

export interface Filters {
  commissioningReference?: string;
  customerAddressStreet?: string;
  customerAddressCity?: string;
  customerAddressZip?: string;
  customerAddressNumber?: string;
  createdAtTo?: string;
  createdAtFrom?: string;
  hasInvoice?: string;
}

export interface Params extends Filters {
  page?: number;
  perPage?: number;
  limit?: number;
}

export interface Accessories {
  withSearch?: boolean;
  withFilter?: boolean;
  withPagination?: boolean;
  withExport?: boolean;
}

export interface Title {
  title: string;
}

interface CellItemListWithHeaders<CellItem> {
  cellItems: CellItem[];
  total?: number;
}

interface ListViewProps<CellItem> extends Accessories {
  getList: (token: string, params?: Params) => Promise<CellItemListWithHeaders<CellItem>>;
  renderList: (items: CellItem[]) => JSX.Element;
  children?: Children;
}

export const ListView = <CellItem extends Title>(props: ListViewProps<CellItem>) => {
  const {
    getList,
    renderList,
    children,
    withSearch,
    withFilter,
    withPagination,
    withExport,
  } = props;

  const stores = useContext(StoreContext);

  const [cellItems, setCellItems] = useState<CellItem[]>([]);

  const [searchTerm, setSEarchTerm] = useState('');

  const [pages, setPages] = useState(1);
  const [more, hasMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(15);

  const fetchResource = useCallback(async () => {
    const params = withPagination
      ? {
        page: currentPage,
        perPage,
      }
      : undefined;

    try {
      const items = await getList(`${stores.auth.token}`, params);
      hasMore(items.cellItems.length < (items.total || 0));
      if (withPagination) {
        setPages(Math.ceil((items.total || items.cellItems.length) / perPage));
      }
      setCellItems(items.cellItems);
    } catch (error) {
      console.error('Failed to fetch resource:', error);
    }
  }, [currentPage, setCellItems, getList, perPage, withPagination, stores.auth.token]);

  useEffect(() => {
    fetchResource();
  }, [fetchResource]);

  const exportCSV = () => {
    const rows = cellItems.map(item => ([
      item.title,
    ]));

    const csvContent = `data:text/csv;charset=utf-8,${
      rows.map(e => e.join(',')).join('\n')}`;

    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  return (
    <Styles>
      {(withSearch || withFilter) && (
        <SearchAndFilterArea>
          {withSearch && (
            <TextField
              badgeTitle={__('Search')}
              badgeEqualsPlaceholder
              value={searchTerm}
              onChange={e => setSEarchTerm(e.target.value)}
              utilityView={<Icon name="search" color={themeConfig.colors.border} />}
            />
          )}
          {withFilter && (
            <Filter />
          )}
        </SearchAndFilterArea>
      )}

      {withExport && (
        <Button onClick={exportCSV}>
          <ButtonTitle>{__('Export')}</ButtonTitle>
        </Button>
      )}

      {renderList(cellItems)}

      {withPagination && (
        <ListPagination pages={pages} onIndexChange={setCurrentPage} />
      )}

      {more && children}
    </Styles>
  );
};
