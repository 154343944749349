import { useContext } from 'react';

import { observer } from 'mobx-react';

import MainRouterContainer from './MainRouterContainer';

import StoreContext from '../../stores/index';
import LoginRouterContainer from './LoginRouterContainer';

interface RouterProps {
  afterElement?: JSX.Element;
}

export default observer(({ afterElement }: RouterProps) => {
  const { auth: { isLoggedIn } } = useContext(StoreContext);

  return (
    isLoggedIn? (
      <>
        <MainRouterContainer />
        {afterElement}
      </>
    ) : (
      <LoginRouterContainer />
    )
  );
});
